<template>
  <div class="create-form">
    <div class="create-form__header">
      <div class="create-form__title">
        {{ $t('schedule.splitMergeSetting.selectOperation') }}
      </div>
      <schedule-select
        v-model="selectedOperation"
        v-validate="'required'"
        :disabled="isLoadingOperations"
        :options="operationOptions"
        name="operationName"
        @input="onSelectOperation"
      />
    </div>
    <div class="create-form__rule">
      <split-merge-form
        by="produceQuantity"
        :default-setting="quantitySetting"
        @change="quantitySetting = $event"
      />
      <split-merge-form
        by="produceDuration"
        :default-setting="timeSetting"
        @change="timeSetting = $event"
      />
    </div>
    <div class="create-form__action">
      <schedule-button
        type="outline"
        @click="$emit('cancel')"
      >
        {{ $t('schedule.button.cancel') }}
      </schedule-button>
      <schedule-button
        :disabled="isProcessing"
        type="secondary"
        @click="onClickAddOperationRule"
      >
        <i class="el-icon-plus" />
        {{ $t('schedule.splitMergeSetting.addRule') }}
      </schedule-button>
    </div>
  </div>
</template>

<script>
import { getOperations } from '@/schedule/API/Process'
import { setOperationSplitMergeRule, getOperationSplitMergeRules } from '@/schedule/API/Setting'
import SplitMergeForm from './components/SplitMergeForm'
import { isSplitMergeSettingValid, isAllValueEmpty } from '@/schedule/utils/settingValidator'
import { stringToFloat } from '@/schedule/utils/utils'
import { mapState } from 'vuex'
import { Message } from 'element-ui'

export default {
  name: 'CreateOperationSplitMergeRule',
  inject: {
    $validator: {},
    // 無值：後台設定
    // 有值：模擬頁面
    $solutionInfo: {
      default: null
    }
  },
  components: {
    SplitMergeForm
  },
  mounted () {
    this.getOperations()
  },
  methods: {
    getOperations () {
      getOperations(this.scheduleProjectId)
        .then(operations => {
          this.operationOptions = operations.map(item => ({ value: item, label: item }))
        })
        .catch(() => {})
        .finally(() => this.isLoadingOperations = false)
    },
    onSelectOperation (operationName) {
      this.resetRuleForm()

      // 用工序名稱，撈取此工序是否已有拆併設定，找到的話填入表格當作預設值
      if (this.solutionInfo) {
        const existingRule = this.solutionRules.find(rule => rule.operationName === operationName)
        if (existingRule) this.setupRuleForm(existingRule)
      } else {
        this.getOperationSplitMergeRules(operationName)
      }
    },
    getOperationSplitMergeRules (operationName) {
      getOperationSplitMergeRules(this.scheduleProjectId, operationName)
        .then(foundOperations => {
          if (foundOperations.length > 0) {
            // 一道工序只能設定一個拆併條件，因此這邊取第一個就好
            const operation = foundOperations[0]
            this.setupRuleForm(operation)
          }
        })
        .catch(() => {})
        .finally(() => {})
    },
    async isOperationRuleValid () {
      const isOperationValid = await this.$validator.validate('operationName')
      return (
        !isAllValueEmpty([
          ...Object.values(this.quantitySetting), ...Object.values(this.timeSetting)
        ],
        this.$t('schedule.splitMergeSetting.atLeastOneThreshold')) &&
        isSplitMergeSettingValid(this.quantitySetting) &&
        isSplitMergeSettingValid(this.timeSetting) &&
        isOperationValid
      )
    },
    async onClickAddOperationRule () {
      if (!await this.isOperationRuleValid()) return

      const newRuleInfo = {
        operationName: this.selectedOperation,
        operationSplitMergeId: this.operationSplitMergeId, // 有帶 id 會更新工序拆併條件、沒帶則會新增一個
        quantitySplitThreshold: stringToFloat(this.quantitySetting.splitThreshold),
        quantityMergeThreshold: stringToFloat(this.quantitySetting.mergeThreshold),
        timeSplitThreshold: stringToFloat(this.timeSetting.splitThreshold),
        timeMergeThreshold: stringToFloat(this.timeSetting.mergeThreshold)
      }
      this.solutionInfo ? this.setOperationRuleForSimulation(newRuleInfo) : this.setOperationRule(newRuleInfo)
    },
    setOperationRuleForSimulation (newRuleInfo) {
      const existingRuleIndex = this.solutionRules.findIndex(rule => rule.operationName === newRuleInfo.operationName)
      const formattedNewRuleInfo = {
        ...newRuleInfo,
        operationSplitMergeId: newRuleInfo.operationName // 單次模擬時暫存使用的 mock id
      }
      if (existingRuleIndex !== -1) {
        this.$set(this.solutionRules, existingRuleIndex, formattedNewRuleInfo)
      } else {
        this.solutionRules.unshift(formattedNewRuleInfo)
      }
    },
    setOperationRule (newRuleInfo) {
      setOperationSplitMergeRule({
        ...newRuleInfo,
        projectId: this.scheduleProjectId
      })
        .then(() => {
          this.$emit('changed')
          Message({
            message: this.$t('schedule.splitMergeSetting.operationSplitMergeRuleAdded'),
            type: 'success',
            duration: 3 * 1000,
            showClose: true
          })
        })
        .catch(() => {})
        .finally(() => {
          this.isProcessing = false
          this.operationSplitMergeId = null
        })
    },
    setupRuleForm (existingRule) {
      this.operationSplitMergeId = existingRule.operationSplitMergeId
      this.quantitySetting.splitThreshold = existingRule.quantitySplitThreshold
      this.quantitySetting.mergeThreshold = existingRule.quantityMergeThreshold
      this.timeSetting.splitThreshold = existingRule.timeSplitThreshold
      this.timeSetting.mergeThreshold = existingRule.timeMergeThreshold
    },
    resetRuleForm () {
      this.quantitySetting = { splitThreshold: null, mergeThreshold: null }
      this.timeSetting = { splitThreshold: null, mergeThreshold: null }
    }
  },
  data () {
    return {
      isLoadingOperations: true,
      isProcessing: false,
      selectedOperation: null,
      operationOptions: [],
      operationSplitMergeId: null,
      quantitySetting: {
        splitThreshold: null,
        mergeThreshold: null
      },
      timeSetting: {
        splitThreshold: null,
        mergeThreshold: null
      }
    }
  },
  computed: {
    ...mapState('scheduleSetting', ['scheduleProjectId']),
    solutionInfo () {
      return this.$solutionInfo ? this.$solutionInfo() : null
    },
    solutionRules () {
      if (!this.solutionInfo) return []
      return this.solutionInfo.splitMergeSetting.operationSplitMergeRules
    }
  }
}
</script>

<style lang="scss" scoped>
.create-form {
  background: rgba(35, 61, 64, 0.6);
  border-radius: 8px;
  padding: 24px;

  &__header {
    align-items: baseline;
    display: flex;
  }

  &__rule {
    .split-merge-form {
      padding: 12px;

      &:nth-child(odd) {
        background: rgba(35, 61, 64, 0.6);
      }

      &:nth-child(even) {
        background: rgba(50, 75, 78, 0.6);
      }
    }
  }

  &__title {
    margin-right: 8px;
  }

  &__action {
    margin-top: 18px;

    .default-button {
      margin-right: 8px;
    }
  }
}
</style>
