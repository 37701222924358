<template>
  <div class="ss-setting">
    <div class="ss-setting__header">
      <h2 class="header__title">
        {{ $t('schedule.splitMergeSetting.name') }}
      </h2>
    </div>
    <div class="ss-setting__body">
      <section class="body__block body__block--basic">
        <div class="block__title">
          {{ $t('schedule.splitMergeSetting.type') }}
          <schedule-button
            v-if="!solutionInfo"
            :disabled="isProcessing"
            class="save-btn"
            @click="setSetting"
          >
            {{ $t('schedule.button.save') }}
          </schedule-button>
        </div>
        <div class="block__form">
          <!-- 選擇拆併單方式：關閉、by工單、by工序 -->
          <schedule-radio-group
            v-model="splitMergeSetting.splitMergeType"
            :options="splitMergeTypeOptions"
            option-display="block"
          >
            <!-- 拆併單：by工單設定 -->
            <product-split-merge-setting
              :default-setting="splitMergeSetting"
              :slot="SPLIT_MERGE_TYPE.PRODUCT"
              @change="onJobTresholdChange"
            />
          </schedule-radio-group>
        </div>
      </section>
      <section class="body__block">
        <div class="block__title">
          {{ $t('schedule.splitMergeSetting.operationSplitMergeRules') }}
        </div>
        <div class="block__form">
          <!-- 當前已設定的工序拆併條件清單 -->
          <operation-split-merge-rules />
        </div>
      </section>
      <section class="body__block">
        <div class="block__title">
          {{ $t('schedule.splitMergeSetting.excludedProductList') }}
          <span class="reminding">
            <i class="el-icon-warning-outline" />
            {{ $t('schedule.splitMergeSetting.excludedProductListDescription') }}
          </span>
        </div>
        <div class="block__form">
          <product-exclude-setting />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import ProductSplitMergeSetting from './ProductSplitMergeSetting'
import OperationSplitMergeRules from './OperationSplitMergeRules'
import ProductExcludeSetting from './ProductExcludeSetting'
import { setSplitMergeSetting } from '@/schedule/API/Setting'
import { mapState } from 'vuex'
import { Message } from 'element-ui'
import { stringToFloat } from '@/schedule/utils/utils'
import { SPLIT_MERGE_TYPE } from '@/schedule/utils/enum'
import { isSplitMergeSettingValid, isAllValueEmpty } from '@/schedule/utils/settingValidator'

export default {
  name: 'SplitMergeSetting',
  inject: {
    // 無值：後台設定
    // 有值：模擬頁面
    $solutionInfo: {
      default: null
    },
    $solutionSequence: {
      default: null
    }
  },
  components: {
    ProductSplitMergeSetting,
    OperationSplitMergeRules,
    ProductExcludeSetting
  },
  data () {
    return {
      isProcessing: false,
      splitMergeSetting: {
        splitMergeType: null,
        splitThreshold: null,
        mergeThreshold: null,
        operationSplitMergeRules: [],
        productSplitMergeBlacklist: []
      },
      SPLIT_MERGE_TYPE
    }
  },
  created () {
    this.initSettingInfo(this.solutionInfo || this.defaultSetting)
  },
  watch: {
    splitMergeSetting: {
      deep: true,
      handler (newVal) {
        if (!this.solutionSequence) return
        this.$store.commit('simulation/updateSolutionBySequence', {
          sequence: this.solutionSequence,
          data: { splitMergeSetting: newVal }
        })
      }
    }
  },
  computed: {
    ...mapState('scheduleSetting', ['scheduleProjectId', 'defaultSetting']),
    solutionInfo () {
      return this.$solutionInfo ? this.$solutionInfo() : null
    },
    solutionSequence () {
      return this.$solutionSequence ? this.$solutionSequence() : null
    },
    splitMergeTypeOptions () {
      return [
        { key: SPLIT_MERGE_TYPE.OFF, label: this.$t('schedule.base.close') },
        { key: SPLIT_MERGE_TYPE.PRODUCT, label: this.$t('schedule.base.by') + this.$t('schedule.base.product') },
        { key: SPLIT_MERGE_TYPE.OPERATION, label: this.$t('schedule.base.by') + this.$t('schedule.base.operation') }
      ]
    },
    isSettingValid () {
      if (this.splitMergeSetting.splitMergeType === SPLIT_MERGE_TYPE.PRODUCT) {
        return (
          !isAllValueEmpty([
            this.splitMergeSetting.splitThreshold,
            this.splitMergeSetting.mergeThreshold
          ],
          this.$t('schedule.splitMergeSetting.atLeastOneThreshold')) &&
          isSplitMergeSettingValid(this.splitMergeSetting)
        )
      }
      return true
    }
  },
  methods: {
    initSettingInfo ({ splitMergeSetting }) {
      this.splitMergeSetting = splitMergeSetting
    },
    setSetting () {
      if (!this.isSettingValid) return

      this.isProcessing = true

      const settingPayload = {
        splitMergeType: this.splitMergeSetting.splitMergeType,
        productSplitThreshold: stringToFloat(this.splitMergeSetting.splitThreshold),
        productMergeThreshold: stringToFloat(this.splitMergeSetting.mergeThreshold)
      }
      setSplitMergeSetting({ ...settingPayload, projectId: this.scheduleProjectId })
        .then(() => {
          Message({
            message: this.$t('schedule.splitMergeSetting.settingUpdated'),
            type: 'success',
            duration: 3 * 1000,
            showClose: true
          })
        })
        .catch(() => {})
        .finally(() => this.isProcessing = false)
    },
    onJobTresholdChange (value) {
      this.splitMergeSetting = {
        ...value,
        splitMergeType: this.splitMergeSetting.splitMergeType
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.body__block {
  &--basic {
    .block__title {
      .save-btn {
        margin-left: 12px;
      }
    }

    .schedule-radio-group {
      width: 100%;
    }
  }
}

.bold {
  font-weight: bold;
}

.spinner-block {
  margin: 0 auto;
}

</style>
