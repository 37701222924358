<template>
  <div class="product-exclude-setting">
    <div class="product-exclude-setting__form">
      <remote-search-select
        :search-method="getProducts"
        :disabled-options="excludeProductNames"
        :placeholder="$t('schedule.base.search') + $t('schedule.base.productId')"
        @exact-select="selectedProductName = $event"
      />
      <schedule-button
        type="outline"
        @click="onClickAdd"
      >
        {{ $t('schedule.setting.create') }}
      </schedule-button>
    </div>
    <div class="product-exclude-setting__list">
      <spinner
        v-if="isLoading || isProcessing"
        size="16"
      />
      <product-exclude-list
        v-else-if="excludeProductNames.length > 0"
        :exclude-product-names="excludeProductNames"
        @delete="onClickDelete"
      />
      <empty-info-block v-else />
    </div>
  </div>
</template>

<script>
import RemoteSearchSelect from '@/schedule/components/RemoteSearchSelect'
import ProductExcludeList from './ProductExcludeList'
import EmptyInfoBlock from '@/components/EmptyInfoBlock.vue'
import { getProducts } from '@/schedule/API/Process'
import {
  getProductSplitMergeBlacklist,
  addProductToSplitMergeBlacklist,
  deleteProductFromSplitMergeBlacklist
} from '@/schedule/API/Setting'
import { mapState } from 'vuex'
import { Message } from 'element-ui'

export default {
  name: 'ProductExcludeSetting',
  inject: {
    // 無值：後台設定
    // 有值：模擬頁面
    $solutionInfo: {
      default: null
    }
  },
  components: {
    RemoteSearchSelect,
    ProductExcludeList,
    EmptyInfoBlock
  },
  data () {
    return {
      isLoading: false,
      isProcessing: false,
      isListChanged: false,
      excludeProductNames: [],
      selectedProductName: ''
    }
  },
  computed: {
    ...mapState('scheduleSetting', ['scheduleProjectId', 'defaultSetting']),
    solutionInfo () {
      return this.$solutionInfo ? this.$solutionInfo() : null
    },
    blacklist () {
      return this.solutionInfo.splitMergeSetting.productSplitMergeBlacklist
    }
  },
  methods: {
    initSettingInfo ({ splitMergeSetting }) {
      this.excludeProductNames = splitMergeSetting.productSplitMergeBlacklist
    },
    getProductSplitMergeBlacklist () {
      this.isLoading = true
      getProductSplitMergeBlacklist(this.scheduleProjectId)
        .then(excludeProductNames => {
          this.excludeProductNames = excludeProductNames
        })
        .catch(() => {})
        .finally(() => this.isLoading = false)
    },
    onClickAdd () {
      if (this.excludeProductNames.indexOf(this.selectedProductName) !== -1) {
        Message({
          message: this.$t('schedule.splitMergeSetting.alreadyExistInSplitMergeBlacklist', { productName: this.selectedProductName }),
          type: 'warning',
          duration: 3 * 1000,
          showClose: true
        })
        return
      }

      this.solutionInfo
        ? this.addProductToSplitMergeBlacklistForSimulation()
        : this.addProductToSplitMergeBlacklist()
    },
    addProductToSplitMergeBlacklistForSimulation () {
      this.excludeProductNames.push(this.selectedProductName)
    },
    addProductToSplitMergeBlacklist () {
      if (this.isProcessing) return
      this.isProcessing = true
      addProductToSplitMergeBlacklist({
        projectId: this.scheduleProjectId,
        product: this.selectedProductName
      })
        .then(() => {
          Message({
            message: this.$t('schedule.splitMergeSetting.productAddedToSplitMergeBlacklist'),
            type: 'success',
            duration: 3 * 1000,
            showClose: true
          })
          this.getProductSplitMergeBlacklist()
        })
        .catch(() => {})
        .finally(() => this.isProcessing = false)
    },
    onClickDelete (productName) {
      this.solutionInfo
        ? this.deleteProdNameFromBlacklistForSimulation(productName)
        : this.deleteProdNameFromBlacklist(productName)
    },
    deleteProdNameFromBlacklistForSimulation (productName) {
      const productNameIndex = this.blacklist.findIndex(item => item === productName)
      if (productNameIndex !== -1) {
        this.blacklist.splice(productNameIndex, 1)
        this.showDeletedMessage()
      }
    },
    deleteProdNameFromBlacklist (productName) {
      if (this.isProcessing) return

      this.isProcessing = true
      deleteProductFromSplitMergeBlacklist({
        projectId: this.scheduleProjectId,
        product: productName
      })
        .then(() => {
          this.showDeletedMessage()
          this.getProductSplitMergeBlacklist()
        })
        .catch(() => {})
        .finally(() => this.isProcessing = false)
    },
    showDeletedMessage () {
      Message({
        message: this.$t('schedule.splitMergeSetting.productRemovedFromSplitMergeBlacklist'),
        type: 'success',
        duration: 3 * 1000,
        showClose: true
      })
    },
    getProducts
  },
  created () {
    if (this.solutionInfo) {
      this.initSettingInfo(this.solutionInfo)
      return
    }
    this.initSettingInfo(this.defaultSetting)
  }
}
</script>
<style lang="scss" scoped>
.product-exclude-setting {
  background: rgba(35, 61, 64, 0.6);
  border-radius: 8px;
  padding: 24px;
  width: 100%;

  &__form {
    display: flex;
    margin-bottom: 16px;

    .filter {
      background: rgba(50, 58, 58, 0.95);
      border-radius: 6px;
      margin-right: 8px;
    }
  }

  &__list {
    .spinner-block {
      padding: 0;
    }

    .empty-text {
      color: #666;
      text-align: center;
    }
  }
}
</style>
