<template>
  <div class="operation-split-merge-rules">
    <div class="operation-split-merge-rules__create-form">
      <schedule-button
        v-show="!isShowCreateRuleForm"
        type="secondary"
        @click="showCreateForm"
      >
        <i class="el-icon-plus" />
        {{ $t('schedule.splitMergeSetting.addRule') }}
      </schedule-button>
      <create-operation-split-merge-rule
        v-if="isShowCreateRuleForm"
        @cancel="cancelCreate"
        @changed="onRuleChanged"
      />
    </div>
    <div class="operation-split-merge-rules__content">
      <spinner v-if="isLoading" />
      <el-table
        v-else
        :data="rules"
        class="ss-table operation-split-merge-rules__table"
        v-loading="isLoading"
      >
        <el-table-column
          prop="operationName"
          :label="$t('schedule.base.operationName')"
        />
        <el-table-column
          :label="$t('schedule.splitMergeSetting.quantity')"
          align="center"
        >
          <el-table-column
            :label="$t('schedule.splitMergeSetting.split')"
            align="right"
          >
            <template slot-scope="scope">
              <span v-html="getThresholdTableCell(scope.row.quantitySplitThreshold)" />
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('schedule.splitMergeSetting.merge')"
            align="right"
          >
            <template slot-scope="scope">
              <span v-html="getThresholdTableCell(scope.row.quantityMergeThreshold)" />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('schedule.splitMergeSetting.duration')"
          align="center"
        >
          <el-table-column
            :label="$t('schedule.splitMergeSetting.split')"
            align="right"
          >
            <template slot-scope="scope">
              <span v-html="getThresholdTableCell(scope.row.timeSplitThreshold)" />
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('schedule.splitMergeSetting.merge')"
            align="right"
          >
            <template slot-scope="scope">
              <span v-html="getThresholdTableCell(scope.row.timeMergeThreshold)" />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('schedule.base.action')"
          width="80"
        >
          <template slot-scope="scope">
            <a
              class="link"
              @click="onClickDeleteRule(scope.row.operationSplitMergeId)"
            >
              {{ $t('schedule.button.delete') }}
            </a>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import CreateOperationSplitMergeRule from './CreateOperationSplitMergeRule'
import { getOperationSplitMergeRules, deleteOperationSplitMergeRule } from '@/schedule/API/Setting'
import { mapState } from 'vuex'
import { Message } from 'element-ui'

export default {
  name: 'OperationSplitMergeRules',
  inject: {
    // 無值：後台設定
    // 有值：模擬頁面
    $solutionInfo: {
      default: null
    }
  },
  components: {
    CreateOperationSplitMergeRule
  },
  watch: {
    isRuleChanged (value) {
      if (value) this.getOperationSplitMergeRules()
    }
  },
  mounted () {
    this.rules = this.solutionInfo
      ? this.solutionInfo.splitMergeSetting.operationSplitMergeRules
      : this.defaultSetting.splitMergeSetting.operationSplitMergeRules
  },
  data () {
    return {
      isLoading: false,
      isDeleting: false,
      isRuleChanged: false,
      isShowCreateRuleForm: false,
      rules: []
    }
  },
  methods: {
    getOperationSplitMergeRules () {
      getOperationSplitMergeRules(this.scheduleProjectId)
        .then(rules => {
          this.rules = rules
          this.isRuleChanged = false
        })
        .catch(() => {})
        .finally(() => this.isLoading = false)
    },
    onClickDeleteRule (ruleId) {
      this.solutionInfo ? this.deleteRuleForSimulation(ruleId) : this.deleteRule(ruleId)
    },
    deleteRuleForSimulation (ruleId) {
      const ruleIndex = this.solutionRules.findIndex(rule => rule.operationSplitMergeId === ruleId)
      if (ruleIndex !== -1) {
        this.solutionRules.splice(ruleIndex, 1)
        this.showDeletedMessage()
      }
    },
    deleteRule (ruleId) {
      if (this.isDeleting) return

      this.isDeleting = true
      deleteOperationSplitMergeRule(ruleId)
        .then(() => {
          this.showDeletedMessage()
          this.getOperationSplitMergeRules()
        })
        .catch(() => {})
        .finally(() => this.isDeleting = false)
    },
    getThresholdTableCell (value) {
      return `<span class="${value ? '' : 'is-gray'}">${value || '-'}</span>`
    },
    cancelCreate () {
      this.isShowCreateRuleForm = false
    },
    showDeletedMessage () {
      Message({
        message: this.$t('schedule.splitMergeSetting.operationSplitMergeRulHasDeleted'),
        type: 'success',
        duration: 3 * 1000,
        showClose: true
      })
    },
    showCreateForm () {
      this.isShowCreateRuleForm = true
    },
    onRuleChanged () {
      this.isShowCreateRuleForm = false
      this.isRuleChanged = true
    }
  },
  computed: {
    ...mapState('scheduleSetting', ['scheduleProjectId', 'defaultSetting']),
    solutionInfo () {
      return this.$solutionInfo ? this.$solutionInfo() : null
    },
    solutionRules () {
      return this.solutionInfo.splitMergeSetting.operationSplitMergeRules
    }
  }
}
</script>

<style lang="scss" scoped>
.operation-split-merge-rules {
  width: 100%;

  &__table {
    width: 100%;
  }

  &__create-form {
    margin-bottom: 16px;
  }
}

::v-deep .is-gray {
  color: #666;
}
</style>
