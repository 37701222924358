<template>
  <div class="split-merge-form">
    <div class="split-merge-form__title">
      <span class="bold">{{ $t(`schedule.splitMergeSetting.${by}`) }}</span>：
    </div>
    <div class="split-merge-form__rule">
      {{ $t('schedule.base.higherThan') }}
      <schedule-input
        v-model="innerSetting.splitThreshold"
        type="number"
        :placeholder="$t('schedule.base.pleaseInput')"
      />
      {{ unit }}{{ $t('schedule.base.than') }}{{ $t('schedule.splitMergeSetting.split') }}
    </div>
    <div class="split-merge-form__rule">
      {{ $t('schedule.base.lowerThan') }}
      <schedule-input
        v-model="innerSetting.mergeThreshold"
        type="number"
        :placeholder="$t('schedule.base.pleaseInput')"
      />
      {{ unit }}{{ $t('schedule.base.than') }}{{ $t('schedule.splitMergeSetting.merge') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SplitMergeForm',
  props: {
    defaultSetting: {
      type: Object,
      default: () => {
        return {
          splitThreshold: Infinity,
          mergeThreshold: -Infinity
        }
      }
    },
    by: {
      type: String,
      default: 'produceQuantity' // produceDuration
    }
  },
  computed: {
    innerSetting: {
      get () {
        return this.defaultSetting
      },
      set (newSetting) {
        this.$emit('change', newSetting)
      }
    },
    unit () {
      switch (this.by) {
        case 'produceDuration':
          return this.$t('schedule.base.minute')
        default:
          return this.$t('schedule.base.unit')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.split-merge-form {
  align-items: baseline;
  display: flex;
  font-size: 14px;

  &__rule {
    display: flex;
    line-height: 40px;
    margin-right: 16px;
  }

  .schedule-input {
    margin: 0 12px;
  }
}
</style>
