import request from '@/schedule/utils/publicRequest.js'

/**
 * 取得子專案下的所有工序名稱清單
 *  @param {Number} projectId - 排程子專案 id
 */
export function getOperations (projectId) {
  return request({
    url: '/process/operation/list',
    method: 'GET',
    params: {
      projectId
    }
  })
}

/**
 * 取得子專案下的所有工序名稱清單
 *  @param {Number} projectId - 排程子專案 id
 */
export function getProducts ({ projectId, keyword = '' }) {
  return request({
    url: '/process/product/list',
    method: 'GET',
    params: {
      projectId,
      keyword
    }
  })
}
