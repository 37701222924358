<template>
  <div class="product-split-merge-setting">
    <split-merge-form
      by="produceQuantity"
      :default-setting="defaultSetting"
      @change="$emit('change', $event)"
    />
  </div>
</template>

<script>
import SplitMergeForm from './components/SplitMergeForm'

export default {
  name: 'ProductSplitMergeSetting',
  components: {
    SplitMergeForm
  },
  props: {
    defaultSetting: {
      type: Object,
      default: () => {
        return {
          splitThreshold: Infinity,
          mergeThreshold: -Infinity
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
