<template>
  <ul class="products-list">
    <li
      v-for="(productName, index) in excludeProductNames"
      :key="index"
      class="products-list__item"
    >
      <div
        class="delete-icon"
        @click="onClickDelete(productName)"
      >
        <svg-icon icon-class="close" />
      </div>
      {{ productName }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ProductExcludeList',
  props: {
    excludeProductNames: {
      type: Array,
      default: [],
      required: true
    }
  },
  methods: {
    onClickDelete (productName) {
      this.$emit('delete', productName)
    }
  }
}
</script>

<style lang="scss" scoped>
.products-list {
  background: rgba(35, 61, 64, 0.6);
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  list-style: none;
  margin: 0;
  padding: 12px;

  &__item {
    align-items: center;
    display: flex;
    margin-right: 8px;
    padding-right: 8px;
    position: relative;

    &:not(:last-child) {
      &::after {
        bottom: 0;
        content: ',';
        position: absolute;
        right: 3px;
      }
    }

    .delete-icon {
      align-items: center;
      background-color: #666;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      height: 14px;
      justify-content: center;
      margin-right: 4px;
      padding: 2px;
      transition: all 0.3s ease;
      width: 14px;

      &:hover {
        background-color: $theme-color-primary;
      }

      .svg-icon {
        color: #fff;
        font-size: 6px;
      }
    }
  }
}

</style>
